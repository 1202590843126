<template>
  <div class="news4">
    <div class="container">
      <el-row :gutter="24">
        <el-col :span="18" style="margin-bottom: 56px">
          <div style="background-color: #fff">
            <div class="news4__title-container">
              <span>首页 > </span>
              <span>新闻资讯 > </span>
              <span>资讯详情</span>
            </div>
            <div class="news4__content-left">
              <div class="news4-title">PCB技术发展的两大趋势</div>
              <div class="news4-createTime">
                <span>发布时间： 2021/12/31 13:32:13</span>
                <span>浏览： 68次</span>
              </div>
              <div style="font-size: 16px;padding-bottom: 64px;border-bottom: 1px solid #d4d4d4;padding: 0 20px;">
                <div class="news4-content">
                   终端设备尺寸不断减小以满足用户对便携性的需求，但板级功能日趋复杂，而且高速信号应用越来越多，以致PCB空间越来越拥挤，上述电子产品多个发展方向都需要PCB小型化。缩小PCB尺寸，或者说提高PCB“集成度”的方法，通常可以细分为如下三种：增加层数，减小线宽线距及孔径直径，以及采用新型材料。
                </div>
			   <div class="news4-content">
				    PCB（印刷电路板）已经成为亚太主导的产业，尤其是中国公司。
			   </div>
			   <div class="news4-content">
			   				  根据国际电子工业联接协会（IPC）数据显示，2017年大中华地区PCB产能占全球63.6%（其中大陆地区占比为52.7%），如果加上韩日，东亚地区占全球PCB产能超过80%。
			   </div>
			   <div class="news4-content">
			   			PCB在欧美渐成夕阳产业，总体呈下降趋势，但有一家总部在美国的PCB公司近年来却一直专心耕耘PCB行业，而且势头良好，不仅以销售额论位居全球三甲，而且2018年增长超过10%，销售额超过28亿美元，并在电子产业全行业预期悲观的时候表示，2019年公司仍然期望实现正增长。
			   </div>
			   <div class="news4-content" style="font-weight: bold;">
			   				    来自美国的PCB厂商
			   </div>
			   <div class="news4-content">
			   				   这家公司就是总部在美国的迅达科技（TTM Technologies, 简称TTM ）。在2019国际智能制造生态链峰会上，迅达科技首席执行官汤姆·艾德曼（Tom Edman）发表主旨演讲，并在会后接受探索科技（TechSugar）独家专访，向读者讲述他所理解的PCB产业。
			   </div>
			   <div class="news4-content" style="text-align: center;margin: 40px 0;">
					   <img width="50%" src="./2.png" alt="">
			   </div>
			   
			  <div class="news4-content">
			   				   迅达科技首席执行官汤姆·艾德曼（Tom Edman）
			   </div>
			   <div class="news4-content">
			   				    艾德曼告诉探索科技（TechSugar），虽然全球约60%产能都在中国，但PCB产业仍然是全球性行业，PCB客户的需求并不只是生产，而是从设计、出样到量产的全方位的支持。迅达科技虽然是美国公司，但全球布局，在北美和亚太有生产基地，客户可以就近选择生产基地。
			   </div>
			   <div class="news4-content">
			   				   艾德曼认为，相比竞争对手，迅达科技有三大优势。首先是技术完整性，他说：“全球很难找到像TTM这样提供一站式服务的PCB公司，无论是超高层硬板、软硬结合板，还是软板，以及高密度互连硬板，包括射频板和PCB基板，我们都能提供。”
			   </div>
			   <div class="news4-content">
			   				    其次，持续在PCB领域经营积累的对PCB技术理解力，有助于帮助迅达科技客户的工程师解决技术难题。“我们的应用工程师能力极强，可以在设计开始阶段就助力客户。
			   </div>
			   <div class="news4-content">
			   				    最后，就是全球布局。迅达科技提供最灵活的供应链配置供客户选择，客户可以在迅达科技的美国工厂出样，最后到其中国工厂量产。“无论是技术，还是供应链，以及组织效率，我们都能满足客户的需求。”艾德曼表示，即便是美国公司，迅达科技在PCB行业仍然极具竞争力。
			   </div>
			   
			   <div class="news4-content" style="font-weight: bold;">
			   				   PCB技术发展趋势
			   </div>
			   <div class="news4-content">
			   				   虽然不具备成本优势，但迅达科技在PCB的高附加值市场表现优异，在通信、国防军工、汽车、医疗及工业等应用市场尤其出色。根据PCB市场调研机构Prismark的数据，在通信PCB市场，2017年迅达科技高居榜首，而且是前五大厂商中唯一的中国厂商。
			   </div>
			   <div c style="text-align: center;margin: 40px 0;">
			   					   <img width="50%" src="./3.png" alt="">
			   </div>
			  <div class="news4-content">
			   				    半导体领域有摩尔定律，单位面积每24个月晶体管集成数量翻倍（摩尔定律第一版翻倍时间是每12个月，在集成度发展到一定阶段后调整为24个月，现在有进一步拉长的趋势），这意味着单个晶体管尺寸缩小，芯片整体集成度提高。PCB行业对尺寸微缩的需求虽然不如半导体产业那样极致，但也伴随电子产业的发展不断提出更高要求。个人计算设备从台式机到笔记本，再进化到平板电脑和手机，设备体积以数量级的形式缩小，其中芯片集成度提高起了主导作用，但PCB尺寸缩小、布线密度提高也是重要的辅助途径。
			   </div>
			   <div  style="text-align: center;margin: 40px 0;">
			   					   <img width="50%" src="./4.png" alt="">
			   </div>
			   <div class="news4-content">
			    				    面对探索科技（TechSugar）的提问，艾德曼表示当前PCB技术发展有两个主要趋势。第一个趋势便是一直在进行的小型化。终端设备尺寸不断减小以满足用户对便携性的需求，但板级功能日趋复杂，而且高速信号应用越来越多，以致PCB空间越来越拥挤，上述电子产品多个发展方向都需要PCB小型化。缩小PCB尺寸，或者说提高PCB“集成度”的方法，通常可以细分为如下三种：增加层数，减小线宽线距及孔径直径，以及采用新型材料。
			    </div>
				<div class="news4-content">
				 				   “高密度互连板（HDI）上线宽线距已经小于80微米，最早应用于手机和平板，过去七八年，这种技术已经从移动设备普及到台式机、汽车电子中的影音娱乐与摄像头模块，以及国防航空领域。”艾德曼指出，80微米并非极限，已经有客户要求30微米以下的线宽线距，“为实现30微米以下的线宽线距，TTM加大了在半加成法（Modified Semi-Additive Process）与类载板（Substrate Like PCB）等技术上的投入，我对TTM开发新技术以满足小型化需求的进度非常乐观。”
				</div>
				<div class="news4-content">
				  				    艾德曼看重的另一个方向是板型（form factor）变化。除了传统硬板PCB，软板以及软硬结合板市场需求“将持续增长，这些技术可应用在可折叠手机，以及微缩封装上。”
				</div>
				<div class="news4-content">
				   				   软硬结合PCB是当前市场热点。用超薄柔性电路带，让多个印刷电路板组件和其他元件（如显示、输入或存储器等）连接起来，无需电线、电缆或连接器。软硬结合印刷电路板模块中各个多层刚性电路板模块间按需要进行连接时所需的线路，由柔性电路板作为其支撑载体。
				</div>
				<div class="news4-content">
				    				    与使用分离式线缆和连接器的标准PCB组合相比，软硬结合板的平均无故障时间 (MTBF) 通常会更长，迅达科技此软板及软硬结合板广泛应用于卫星、军用飞机和导弹平台、各种医疗健康设备，以及不同的科学与工业应用中。
				</div>
				<div class="news4-content" style="font-weight: bold;">
				    				   PCB与智能制造
				</div>
				<div class="news4-content">
				    				      智能制造、工业4.0是当今的热门话题，类似迅达科技这样的领先厂商，自然需要思考，如何将PCB制造业带入到流程更智能、质量可追溯、生产效率高的智能制造时代。艾德曼表示，PCB智能工厂并只是IT策略，也不只是自动化制造，PCB智能工厂要能够实现流程控制实时可见、生产因素完全可控，能够满足PCB厂商及客户对环境、技术、质量、产能、成本收益，以及数据追踪的所有需求。
				</div>
				<div  style="text-align: center;margin: 40px 0;">
									   <img width="50%" src="./5.png" alt="">
				</div>
				<div class="news4-content">
				    				  但是，传统PCB产线向智能工厂发展时面临很多困难。例如，传统PCB产线设备通常不联网，而且不同环节的供应商不一样，设备之间互联互通难度较大，行业内也缺乏统一的设备通信标准，生产流程可追溯性差。PCB行业的MES系统又原始粗糙，与半导体或面板制造业相比，PCB设备自动化程度低，人工操作多，再加上PCB制造毛利率比较低，所以在设备投资上捉襟见肘，要跨入工业4.0并不容易。
				</div>
				<div class="news4-content">
				    				     不过，艾德曼认为，PCB实现智能制造的趋势不可逆转，从业者所需要考虑的只是如何去实现。以迅达科技的视角来看，在PCB产线上实现数据采集及互连互通是第一步，一旦机器的数据被及时收集并记录，PCB厂商就可以通过数据分析来优化生产流程，改进生产计划，让全流程制造实现可视化监控。
				</div>
				<div class="news4-content">
				    				    但他最后又强调，PCB制造工艺超过50步，非常复杂，因此把PCB产线升级到智能制造只能一步一步来，千万要杜绝一蹴而就的思路。“先在局部环节实现智能制造，然后在逐步扩展，一步到位需要太多投入，也会冒更大风险，一次改进一个环节（one step at a time），这是适合PCB产业迈向智能制造的节奏。”
				</div>
              </div>

              <div style="padding-top: 20px;padding-bottom: 40px">
                <router-link to="/news6">
                  <span style="color: #DA261D;font-size: 16px">< 上一篇</span>
                </router-link>
				<router-link to="/news4">
				 <span class="fr" style="color: #DA261D;font-size: 16px">下一篇 ></span>
				</router-link>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div style="background-color: #fff;padding: 24px">
            <div style="font-size: 20px;font-weight: bold">热点资讯</div>
            <div style="border-bottom: 1px solid #D4D4D4">
              <p style="margin-top: 18px;margin-bottom: 8px;font-size: 16px">SMT贴片检验有哪些标准</p>
              <p style="font-size: 12px;color: #A6A6A6;margin-bottom: 8px">2021-01-22 15:47</p>
            </div>
            <div style="border-bottom: 1px solid #D4D4D4;margin-top: 32px">
              <p style="margin-top: 18px;margin-bottom: 8px;font-size: 16px">SMT贴片检验SMT对于电子行……</p>
              <p style="font-size: 12px;color: #A6A6A6;margin-bottom: 8px">2021-01-22 15:47</p>
            </div>
            <div style="border-bottom: 1px solid #D4D4D4;margin-top: 32px">
              <p style="margin-top: 18px;margin-bottom: 8px;font-size: 16px">什么是PCBA代工代料，PCBA……</p>
              <p style="font-size: 12px;color: #A6A6A6;margin-bottom: 8px">2021-01-22 15:47</p>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import "@/styles/common.scss";

.news4 {
  background: #F6F6F6;

  .container {
    margin-top: 24px;
    padding: 0 gap();
  }
}

.news4__title-container {
  font-size: 14px;
  padding-top: 16px;
  padding-left: 20px;
  padding-bottom: 20px;
  background-color: #fff;

  span:nth-of-type(3) {
    color: #a6a6a6;
  }
}

.news4__content-left {
  margin-left: 20px;
  margin-right: 20px;
  background-color: #fff;

  .news4-title {
    font-size: 24px;
    font-weight: bold;
  }

  .news4-createTime {
    padding: 10px 0;
    font-size: 14px;
    color: #A6A6A6;
    border-bottom: 1px solid #D4D4D4;

    span:first-of-type {
      margin-right: 32px;
    }
  }

  .news4__p-1 {
    margin: 20px 0;

    line-height: 1.5;
  }

  .news4__p-2,
  .news4__p-3,
  .news4__p-4 {

    line-height: 1.5;
  }
}

.news4-subTitle {
  font-size: 16px;
  font-weight: bold;
  margin: 20px 0 8px 0;
}

.news4-subTitle-subTitle {
  margin: 10px 0;
}

.news4-content {
margin-top: 10px;
  text-indent: 2em;
  line-height: 1.5;
}
</style>
